@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  background-color: #fcfcfc;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
  color: #212529;
}

a {
  text-decoration: none;
  color: #000;
}

li {
  list-style: none;
}

button {
  padding: 1rem 2rem;
  border-radius: 6px 0 6px 6px;
  border: 1px solid #c3053c;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  cursor: pointer;
  text-transform: uppercase;
  /* font-family: "Lato", sans-serif; */
}

.btn1Style {
  background-color: white;
  color: #c3053c;
}

.btn2Style {
  background-color: #c3053c;
  color: white;
  margin-left: 1rem;
}

.hero-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 27rem;
  padding-top: 10.5rem;
}

.hero-banner h1 {
  color: white;
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  section > div {
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
  }
}
