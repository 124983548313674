header > div {
  justify-content: space-between;
  align-items: center;
  padding: 2rem 6rem;
}

header img {
  width: 13rem;
  height: auto;
}

nav,
ul {
  display: flex;
  gap: 2rem;
}

nav {
  align-items: baseline;
  font-weight: 500;
}

nav > div {
  display: flex;
}

nav a {
  font-family: "Raleway", sans-serif;
  color: #727272;
  font-size: 1.2rem;
}

nav a:focus,
nav a:active,
nav a:hover {
  color: #c3053c;
}

.mobile-menu {
  display: none;
  height: 100vh;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 768px) {
  .hamburger,
  .cancel > button {
    display: block;
    border: none;
    background: none;
  }

  .cancel {
    display: flex;
    justify-content: flex-end;
  }

  .hamburger img,
  .cancel img {
    width: 2rem;
  }

  .menu-desktop {
    display: none;
  }

  .mobile-menu nav,
  .mobile-menu ul,
  nav > div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  nav,
  ul {
    gap: 3rem;
  }

  nav > div .btn2Style {
    margin-left: 0;
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 425px) {
  header > div {
    padding: 2rem 2.5rem;
  }

  .hamburger {
    padding: 1rem 1rem;
  }

  .cancel img {
    width: 1.5rem;
  }
}
